body {
  font-family: $poppins;
  color: $font_color;
  line-height: 1.15em;
  -webkit-tap-highlight-color: $black;
  -webkit-tap-highlight-color: transparent; /* For some Androids */
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
}

h1 {
	font-weight: 600;
	line-height: 1.4rem;
	letter-spacing: -0.045em;
}

h2 {
	font-weight: 400;
	letter-spacing: -0.04em;
}

h3 {
	letter-spacing: -0.04rem;
}

p {
  font-family: $poppins !important;
	font-weight: 400;
	letter-spacing: -0.01em;
	line-height: 1.2rem;
}

.link {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
}