































































@import "@/assets/scss/variables.scss";

main {
  @media screen {
    background-color: $background-color;
    display: flex;
    height: 100%;
    flex-direction: column;

    #columns {
      touch-action: auto;
      flex-grow: 1;
      padding-left: 2rem;
      padding-right: 2rem;
      margin-top: 12px;
      display: flex;
      user-select: none;
      scroll-snap-type: x mandatory;
      overflow-x: scroll;
      height: 100%;
    }
  }
  @media print {
    #columns {
      display: none;
    }
  }
}

@media screen {
  .night-mode main {
    @media screen {
      background-color: $background-color-night;
    }
  }
}

// .list-leave-to {
//   transform: translate(0%, -100%);
// }
.list-leave-active, .list-move {
  transition: transform 0.5s;
}
