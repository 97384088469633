@import url('~normalize.css');

* {
  box-sizing: border-box;
}

@import 'variables';
@import 'fonts';
@import 'typography';
@import 'icons';
@import 'forms';
@import 'print';

@media screen {
  html, body {
    height: 100%;
  }
}

.toasted-container{
  .toasted-primary{
    background: $secondary-color !important;
  }
}
