@import 'colors';

/*********************
FONTS
*********************/

$poppins: 'Poppins', sans-serif;

/*********************
BREAKPOINTS
*********************/

@mixin breakpoint($point) {
  @if $point == mobile {
    @media (max-width: 640px) { @content ; }
  }
}

/*********************
VARIABLES
*********************/

$sidebar-size: 68px;
$column-size: 310px;
$max-document-width: 704px;

$primary-color: #4d4d4d;
$secondary-color:#325AFB;

$font-color: #231f20;
$danger-color: #dc3545;

$dark: black;
$light: white;

$background-color: mix(#f3f5f6,#E1E8ED,50);
$background-color-night: mix($font-color,$grey-cold-5, 90);

$urgency-bar-width: 12px;

$form-element-height: 48px;
