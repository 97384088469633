








































































































































@import "@/assets/scss/variables.scss";
  .modal-mask {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  h1 {
    font-size: 1.17em;
    letter-spacing: -0.04rem;
    line-height: inherit;
  }
  form {
    &>*{
      margin-bottom: 17px;
    }
    &.danger {
      input {
        outline: 1px solid $danger-color;
        background-color: rgba($danger-color, 0.2);
      }
    }
  }
  .cookies {
    color: $grey-cold-5;
  }
