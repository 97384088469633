


























@import "@/assets/scss/variables.scss";
#app {
  height: 100%;
}
