@page {
  margin-top: 2cm;
  margin-bottom: 2cm;
  margin-left: 3cm;
  margin-right: 3cm;
}

body {
  -webkit-print-color-adjust: exact !important;
}

