$font_path: '~@/assets/fonts/';

// *********** //
// POPPINS-Fix //

@font-face {
  font-family: 'Poppins';
  font-display: swap;
  src: url($font_path+'PoppinsFix-400.eot'); /* IE9 Compat; Modes */
  src: url($font_path+'PoppinsFix-400.eot?#iefix') format('embedded-opentype'),
  /* IE6-IE8 */ url($font_path+'PoppinsFix-400.woff2') format('woff2'),
  /* Modern Browsers */ url($font_path+'PoppinsFix-400.woff') format('woff'); /* Modern Browsers */
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Poppins';
  font-display: swap;
  src: url($font_path+'PoppinsFix-600.eot'); /* IE9 Compat; Modes */
  src: url($font_path+'PoppinsFix-600.eot?#iefix') format('embedded-opentype'),
  /* IE6-IE8 */ url($font_path+'PoppinsFix-600.woff2') format('woff2'),
  /* Modern Browsers */ url($font_path+'PoppinsFix-600.woff') format('woff'); /* Modern Browsers */
  font-style: normal;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}
// [END] POPPINS-Fix //
// ***************** //


// *********** //
// UI Essential //

// FULL
@font-face{
    font-family: 'UI essential';
    font-display: block;
    src: url($font_path +'ui-essential-normal-full.eot'); /* IE9 Compat; Modes */
    src: url($font_path +'ui-essential-normal-full.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url($font_path +'ui-essential-normal-full.woff2') format('woff2'), /* Modern Browsers */ url($font_path +'ui-essential-normal-full.woff') format('woff'); /* Modern Browsers */
    font-style: normal;
    font-weight: 400;
    text-rendering: optimizeLegibility;
}
// [END] UI Essential //
// **************** //